@import '/src/app/styles/vars.scss';

@function getCheckIcon($color: 'fff') {
  @return url("data:image/svg+xml,%3Csvg fill='%23#{$color}' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width='20px' height='20px'%3E%3Cpath d='M15.71,6.29c-0.39-0.39-1.02-0.39-1.41,0c-0.31,0.31-3.76,3.76-5.29,5.29c-0.81-0.81-2.01-2.01-2.29-2.29c-0.39-0.39-1.02-0.39-1.41,0c-0.39,0.39-0.39,1.02,0,1.41c0.39,0.39,2.61,2.61,3,3c0.39,0.39,1.02,0.39,1.41,0c0.39-0.39,5.61-5.61,6-6C16.1,7.32,16.1,6.68,15.71,6.29z'/%3E%3C/svg%3E");
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'] {
    --active: #000;
    --active-inner: #fff;
    --hover-inner: #000;
    --border: #e5e5e5;
    --border-hover: #000;
    --background: #fff;
    --disabled: #e5e5e5;
    --disabled-inner: #e5e5e5;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    flex-shrink: 0;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    border-radius: 5px;
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    cursor: pointer;

    &.noEvents {
      pointer-events: none;
    }

    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      transition: opacity var(--d-o, 0.2s);
      opacity: var(--o, 0);
      background-position: center;
      background-image: getCheckIcon();

      &:disabled {
        background-image: getCheckIcon('727272');
      }
    }

    &:required:not(:hover):not(:checked):not(:disabled) {
      border: 1px solid $colorRed;
    }

    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --o: 1;
    }

    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          border-width: 2px;
          --bc: var(--active);
        }
      }
    }

    &:focus {
      border-color: var(--border-hover);
      border-width: 2px;
    }

    &:hover {
      &:checked {
        &:after {
          border-color: var(--active-inner);
        }
        &:not(:disabled) {
          --bc: var(--active);
        }
      }
    }

    &:disabled {
      --b: var(--disabled);
      --o: 1;
      border: 1px solid var(--disabled);
      cursor: not-allowed;
      opacity: 1;
      background-position: center;

      &:checked {
        &:after {
          background-image: getCheckIcon('727272');
        }
        --b: var(--disabled-inner);
      }

      &:not(.disabled-demo) + label {
        color: $colorGrayTwo;
        cursor: not-allowed;
      }

      &.disabled-demo + label {
        cursor: default;
      }
    }
  }
}

.disabled-demo[type='checkbox']:after {
  background-image: getCheckIcon('808080');
}

.checkbox {
  display: flex;
  align-items: center;

  &.hide {
    display: none;
  }
}

.label {
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
  padding-left: 5px;
  user-select: none;
  cursor: pointer;
}
