@import '/src/app/styles/vars.scss';

.header {
  padding-top: 16px;

  &.index {
    margin-bottom: 42px;
  }

  &.cabinet {
    position: relative;
    z-index: 12;
    padding-bottom: 15px;
    margin-bottom: 25px;
  }

  @media (min-width: $tabletWidth) {
    &.index,
    &.cabinet {
      padding-bottom: 30px;
      padding-top: 34px;
      margin-bottom: 43px;
    }
    &.index {
      margin-bottom: 50px;
    }
  }
  @media (min-width: $laptopWidth) {
    &.index {
      margin-bottom: 45px;
    }
  }

  .wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (min-width: $tabletWidth) {
      align-items: center;
    }
    @media (min-width: $laptopWidth) {
      flex-wrap: nowrap;
    }
  }
}
